import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  responsive: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  }
}));

/**
 * Component to embed YouTube Videos
 *
 * @param {Object} props
 */
const YouTubeEmbed = ({ url }) => {
  const classes = useStyles();
  const iFrameWidth = window.innerWidth*.8 < 940 ? window.innerWidth*.8 : 940;
  const iFrameHeight = iFrameWidth/(16/9);
  
  return (<div className={classes.responsive}>
    <iframe
      width={iFrameWidth}
      height={iFrameHeight}
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>);
};

YouTubeEmbed.propTypes = {
  /**
   * Url of YouTube Video
   */
  url: PropTypes.string,
};

export default YouTubeEmbed;
