export const light = {
  "alternate": {
    "main": "rgb(247, 249, 250)",
    "dark": "#e8eaf6",
  },
  "cardShadow": "rgba(23, 70, 161, .11)",
  "type": "light",
  "primary": {
    "light": "#001bbf",
    "main": "#00148B",
    "dark": "#000d59",
    "contrastText": "#fff"
  },
  "secondary": {
    "light": "#35BA62",
    "main": "#2C9C52",
    "dark": "#248044",
    "contrastText": "#fff"
  },
  "text": {
    "primary": "#333",
    "secondary": "#555",
    "footer": "rgba(255, 255, 255, 0.8)",
    "footerHover": "rgba(255, 255, 255, 0.6)"
  },
  "divider": "rgba(0, 0, 0, 0.12)",
  "background": {
    "paper": "#fff",
    "default": "#fff",
    "level2": "#f5f5f5",
    "level1": "#fff",
    "footer": "#2C9C52",
  },
  "accent": {
    "blue": "#4598e1",
    "green": "#9fdd9c",
    "red": "rgb(253, 122, 108)",
    "yellow": "rgb(244, 207, 105)"
  }
};

export const dark = {
  "alternate": {
    "main": "#2D3748",
    "dark": "#24242b",
  },
  "cardShadow": "rgba(0, 0, 0, .11)",
  "common": {
    "black": "#000",
    "white": "#fff"
  },
  "type": "dark",
  "primary": {
    "light": "#88bae5",
    "main": "#4598e1",
    "dark": "#3f5970",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "secondary": {
    "light": "#9fdd9c",
    "main": "#35BA62",
    "dark": "#248044",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "text": {
    "primary": "#EEEEEF",
    "secondary": "#AEB0B4",
    "footer": "rgba(255, 255, 255, 0.8)",
    "footerHover": "rgba(255, 255, 255, 0.6)"
  },
  "divider": "rgba(255, 255, 255, 0.12)",
  "background": {
    "paper": "#1A202C",
    "default": "#121212",
    "level2": "#333",
    "level1": "#2D3748",
    "footer": "#18181f",
  },
  "accent": {
    "blue": "#4598e1",
    "green": "#9fdd9c",
    "red": "rgb(253, 122, 108)",
    "yellow": "rgb(244, 207, 105)"
  }
};
